<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Departamentos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Departamentos</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_departamento_export"
                        v-if="$store.getters.can('admin.departamentos.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-departamentos"
                        v-show="departamentos != null"
                        @click="abrirModal('Crear', null)"
                        v-if="$store.getters.can('admin.departamentos.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        País
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.pais"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="pais in listasForms.paises"
                            :key="pais.id"
                            :value="pais.id"
                          >
                            {{ pais.nombre }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Departamento
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.descripcion"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="departamento in departamentos.data"
                      :key="departamento.id"
                    >
                      <td>{{ departamento.pais.nombre }}</td>
                      <td>{{ departamento.descripcion }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            departamento.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ departamento.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-departamentos"
                            @click="abrirModal('Editar', departamento.id)"
                            v-if="
                              $store.getters.can('admin.departamentos.show')
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <!-- Funcionalidad oculta por solicitud del cliente 
                            los métodos no fueron quitados -->
                          <!-- <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(departamento.id)"
                            v-if="
                              $store.getters.can('admin.departamentos.delete')
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="departamentos.total">
                  <p>
                    Mostrando del <b>{{ departamentos.from }}</b> al
                    <b>{{ departamentos.to }}</b> de un total:
                    <b>{{ departamentos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="departamentos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-departamentos">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="pais_id">País</label>
              <select
                id="pais_id"
                class="form-control form-control-sm"
                v-model="objDepartamento.pais_id"
                :class="
                  $v.objDepartamento.pais_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="pais in listasForms.paises"
                  :key="pais.id"
                  :value="pais.id"
                >
                  {{ pais.nombre }}
                </option>
              </select>
              <div class="error" v-if="!$v.objDepartamento.pais_id.required">
                Seleccione un país
              </div>
            </div>
            <div class="form-group">
              <label for="descripcion">Departamento</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="descripcion"
                placeholder="Descripcion"
                v-model="objDepartamento.descripcion"
                :class="
                  $v.objDepartamento.descripcion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
                :readonly="!objDepartamento.pais_id"
                @blur="validaDepartamento"
              />
              <div
                class="error"
                v-if="!$v.objDepartamento.descripcion.required"
              >
                Ingrese un departamento
              </div>
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="objDepartamento.estado"
                :class="
                  $v.objDepartamento.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.id"
                  :value="estado.id"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objDepartamento.estado.required">
                Seleccione un estado
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.objDepartamento.$invalid"
              @click="save()"
              v-if="
                $store.getters.can('admin.departamentos.create') ||
                $store.getters.can('admin.departamentos.show')
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <DepartamentoExport ref="DepartamentoExport" />
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, between } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import DepartamentoExport from "./DepartamentoExport";

export default {
  name: "DepartamentoIndex",
  components: {
    pagination,
    Loading,
    DepartamentoExport,
  },
  data() {
    return {
      filtros: {
        descripcion: null,
        estado: 1,
      },
      departamentos: {},
      objDepartamento: {},
      listasForms: {
        paises: [],
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      cargando: false,
    };
  },
  validations: {
    objDepartamento: {
      pais_id: {
        required,
      },
      descripcion: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/departamentos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.departamentos = response.data;
        });
    },

    getPaises() {
      axios.get("/api/admin/paises/lista").then((response) => {
        this.listasForms.paises = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    abrirModal(accion, idDepartamento) {
      this.getEstados();
      this.getPaises();
      this.modal.accion = accion;
      this.modal.title = accion + " Departamento";
      if (accion == "Editar") {
        this.show(idDepartamento);
      } else {
        this.objDepartamento = {};
      }
    },

    validaDepartamento() {
      let params = {
        pais: this.objDepartamento.pais_id,
        descripcion: this.objDepartamento.descripcion,
      };
      axios
        .get("/api/admin/departamentos/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.objDepartamento.descripcion = null;
            this.$swal({
              icon: "error",
              title: "El departamento digitado ya está creado...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    save() {
      if (!this.$v.objDepartamento.$invalid) {
        this.cargando = true;
        axios
          .post("/api/admin/departamentos", this.objDepartamento)
          .then((response) => {
            this.$refs.closeModal.click();
            this.objDepartamento = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    show(idDepartamento) {
      axios
        .get("/api/admin/departamentos/show/" + idDepartamento)
        .then((response) => {
          this.objDepartamento = response.data;
        });
    },

    destroy(idDepartamento) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/admin/departamentos/" + idDepartamento)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getPaises();
    this.getEstados();
  },
};
</script>